import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import SliderSwiper from "../components/sliderSwiper/sliderSwiper";
import DividerBorder from "../components/dividerBorder";
import CertificateMVber from "../components/assistencia/certificateMVber";
import SlideWithComponent from "../components/sliderSwiper/slideWithComponent";
import SwiperWithComponentMobile from "../components/sliderSwiper/swiperWithComponentMobile";
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

const AssistenciaPage = ({ data }) => (
  <>
    <SEO title="Assistência BMW" image={data.assistenciaJson.intro.background.childImageSharp.fluid.src}/>
    <Layout>
      {useBreakpoint().mobile ? (
        <>
          <HeaderMobile data={data.globalJson.header} />

          <SliderSwiper data={[data.assistenciaJson.introMobile]} center  top oneSlideBorder={false}/>

          <DividerBorder text={false} inverse={false} mobile={true} />

          <CertificateMVber data={data.assistenciaJson.certificate} />

          <DividerBorder text={false} inverse={true} mobile={true} />

          <SwiperWithComponentMobile slide={data.assistenciaJson.mobile.multimediaText} small text={data.assistenciaJson.dividier} justify />

          <FooterMobile data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} />
        </>
      ) : (
        <>
          <Header data={data.globalJson.header} />

          <SliderSwiper data={[data.assistenciaJson.intro]} center oneSlideBorder={false} top />

          <DividerBorder text={false} inverse={false} mobile={false} />

          <CertificateMVber data={data.assistenciaJson.certificate} />

          <DividerBorder text={false} inverse={true} />

          <SlideWithComponent slide={data.assistenciaJson.multimediaText} bg={data.assistenciaJson.scroll} text={data.assistenciaJson.dividier} justify />

          <Footer data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} />
        </>
      )}
    </Layout>
  </>
)

export default AssistenciaPage

export const Json = graphql`
  query assistencia {
    assistenciaJson {
      intro {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      introMobile {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      certificate{
        background 
        
        backgroundMobile 
        imgCertificate {
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      scroll{
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        backgroundMobile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      dividier{
        title
        dividerClass
        subtitle
      }
      multimediaText{
        video
        img{
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
        btnText
        btnLink
        btnClass
      }
      mobile{
        multimediaText{
          video
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description
          btnText
          btnLink
          btnClass
        }
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
        
      }
      floatButtons{
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
