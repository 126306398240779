import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

import Img from "gatsby-image"

const CertificateMVber = ({ data }) => {
    return (
        <CertificateMVberStyled >
            <BgCertificateMvberStyled bg={`url("${data.background}")`} />
            <div className="container-content">
                <div className="container-certificate-img">
                    <Img
                        fluid={data.imgCertificate.childImageSharp.fluid}
                        alt={data.title}
                    />
                </div>
                <div className="container-mvber-text">
                    <h1 className={font.oL + " intro-title"}>
                        {data.title}
                    </h1>
                    <p className={font.oL + " intro-text justify"}>
                        {data.description}
                    </p>
                </div>
            </div>
        </CertificateMVberStyled>
    )
}

export default CertificateMVber

const BgCertificateMvberStyled = styled.div`
background-repeat: no-repeat;
background-position: center;
background-size: contain;
background-image:  ${(props) => props.bg || ''};
right: 0;
z-index: 99;
display: block;
width: 100%;
height: 100%;
position: absolute;
background-color: #ffffff;
max-width: 65vw;

@media (max-width: 990px){
    margin-top: 20vh !important;
    background-color: transparent;
    max-width: 100%;
}
`

const CertificateMVberStyled = styled.div`
height: 80vh;
background-color: #ffffff;
background-repeat: no-repeat;
background-size: 120vh;
background-position: 110% 85%;
margin-top: -5vh;
align-content: center;
display: grid;
left: 0;
position: relative;

.justify{
    text-align: justify !important;
}
.container-content{
    width: 25%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    margin-left: 10%;
    z-index: 100;
}
.container-certificate-img{
    width: 13vh;
    .gatsby-image-wrapper, img{
        height: 100%;
        object-fit: contain !important;
        width: auto;
    }
}

.container-mvber-text{
    text-align: left;
    margin-top: 3vh;
    .uppercase{
        text-transform: uppercase;
    }
    .intro-title{
        margin: 0 0 0.5em;
        text-transform: uppercase;
        color: #0f0f0f;
    }
    .intro-text{
        margin: 4vh auto 4vh;
        color: #0f0f0f;
        text-align: justify;
    }
}

@media (max-width: 990px){
    background-size: 50vh !important; 
    align-content: start;
    background-position: 55% 90%;
    .container-mvber-text {
        margin-top: 1vh;
    }
    .container-certificate-img{
        width: 12vh;    
        margin: auto;
    }
    .container-content{
        width: 80%;
        margin-top: 10vh;
    }
    .container-mvber-text .intro-title, .intro-text{
        text-align: center;
    }
    .intro-text{
        margin: 2vh auto 2vh;
        text-align: justify;
    }
}
`